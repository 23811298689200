import axios from "axios";

export const TABS = [
    {display : "ओवरव्यू" , page : "overview" , url : "" , keyToShow : ""},
    {display : "पॉइंट्स टेबल" , page : "points-table" , url : "points-table" , keyToShow : ""},
    {display : "शेड्यूल" , page : "matches" , url : "schedule" , keyToShow : ""},
    {display : "स्टैट्‍स" , page : "stats" , url : "stats" , keyToShow : "ShowStats"},

]
export const SERIES_ID = 8322
export const APIS = {
    // matches : {
    //     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
    //     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/",
    //     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent___hn_json/",
    // },
    matches : {
        live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
        upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/",
        recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent_hn_json/",
    }
}


export const goToMatchDetails = async (matchId) => {

  //fetch the status of the match
  const response = await axios.get(
    `${
      process.env.NEXT_PUBLIC_BASE_URL
    }/api/getRecentMatch?id=${matchId}&nocache=${new Date().getTime()}`
  );
  let fetchedData = response?.data ?? null;
  if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
    const data = fetchedData?.data?.response?.scorecard;
    let page = null
    if(data.is_live == 1){
      page = 'live'
    }
    else if(data.upcoming == 1){
      page = 'info'
    }
    else{
      page = 'summary'
    }
    window.location.href = `/sports/cricket/champions-trophy/${data?.page_slug_st}/stats/${page}`

  }    
  
}

export const getMatchDetails = async (matchId) => {

  //fetch the status of the match
  const response = await axios.get(
    `${
      process.env.NEXT_PUBLIC_BASE_URL
    }/api/getRecentMatch?id=${matchId}&nocache=${new Date().getTime()}`
  );
  let fetchedData = response?.data ?? null;
  if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
    const data = fetchedData?.data?.response?.scorecard;
    return data

  }    
  
}



// const MATCH_DETAIL_BASE_URL = 

// 6553

/**
* Sends an event to Google Analytics.
*
* @param {object} params - The event parameters.
* @param {string} params.eventName - The name of the event.
* @param {string} params.pageTitle - The title of the page where the event occurred.
* @param {string} params.pageLocation - The full URL of the page where the event occurred.
* @param {string} params.searchQuery - (Optional) The full URL of the page where the event occurred.
* @param {object} patams.object - Any other extra object along with the events.
*/
function sendGAEvent({
    eventName = "",
    pageTitle = "",
    pageLocation = "",
    propertyObject = {},
    searchQuery = undefined,
  }) {
    // Check if gtag is defined on the window object
    const GACode = process.env.NEXT_PUBLIC_GACODE ?? "";
    if (
      typeof window.gtag === "function" &&
      eventName &&
      pageTitle &&
      pageLocation
    ) {
      // console.log("GA event SENT", {
      //   eventName,
      //   page_title: pageTitle,
      //   page_location: pageLocation,
      //   sent_to: GACode,
      // });
      let obj = {
        page_title: pageTitle,
        page_location: pageLocation,
        send_to: GACode,
        ...propertyObject
      };
      if (searchQuery) {
        obj.search_query = searchQuery;
      }
      window.gtag("event", eventName, obj);
    } else {
      console.warn("gtag is not defined. Unable to send event to GA.");
    }
  }
// export default sendGAEvent;

export const callGaEvent = ({eventName ,  pageTitle , propertyObject = {} }) =>{
    console.log("the event is called");
    sendGAEvent({ eventName: eventName, pageTitle: pageTitle,
      pageLocation: window?.location?.href , propertyObject})
  }